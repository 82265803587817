import React from 'react';
import "./Header.scss";

const Header = () => {
    return (
        <div className='Header_container'>
            {/* <div className='Header_title'>REX's portfolio</div> */}
        </div>
    );
};

export default Header;