import React from "react";
import "./Footer.scss";

const Footer = () => {
  return (
    <main className="Footer">
      Copyright 2024. Jaehyun Ahn Frontend developer portfolio
    </main>
  );
};

export default Footer;
